import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import LogoStream from "../components/img/LogoStream"

const AccessibililtyPage = () => {
    return <Layout>
        <Seo
            title={`Accessibility`}
            description={``}
        />

        <LogoStream />

        <div className="content-wrapper">

            <div className="main">
                <h1>Accessibility</h1>

                <p>Dining out portal is working hard to provide access to services and information that are helpful to all of our guests. Our website currently meets Priority 2 of the <a target="_blank" rel="noreferrer" href="http://www.w3.org/TR/1999/WAI-WEBCONTENT-19990505/">W3C Web Content Accessibility Guidelines</a> and we are constantly testing to ensure we maintain these standards.</p>


                <h2>Our website has been designed with the following accessible guidelines in mind:</h2>
                <ul>
                    <li>The site uses a stylesheet and relative font sizes on all text with the exception of graphical text. The font size of text can be changed in size by using the "view" option in the browser and selecting your desired "font size".</li>
                    <li>All images, where appropriate, have an alternative text attribute. This means that when an image is conveying important information its content is described with an alternative text.  Where an image is used for decorative purposes the alternative text attribute has been left blank.</li>
                </ul>

                <h2>Browser Compatibility - The standard supported browsers are</h2>
                <ul>
                    <li>Microsoft Edge</li>
                    <li>Safari</li>
                    <li>Firefox</li>
                    <li>Google Chrome</li>
                </ul>
            </div>
        </div>
    </Layout>
}

export default AccessibililtyPage
